/* styling for the navbar buttons */
.custom-gray-button {
    position: relative;
    overflow: hidden;
    background-color: transparent;
    color: #5B6B7C; /* Assuming this is your gray[500] */
    transition: color 0.3s ease; /* Transition for a smooth color change */
    border-radius: 8px; /* Ensure the button itself has rounded corners */
  }
.custom-gray-button::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  background-color: #9CCCFC; /* Assuming this is your gray[400] */
  transition: all 0.3s ease;
  transform: translate(-50%, -50%) scale(0);
  z-index: -1;
  border-radius: 4px; /* Adjust this value to control the roundness */
}
.custom-gray-button:hover::after,
.custom-gray-button:focus::after {
  transform: translate(-50%, -50%) scale(1);
  width: 90%; /* Adjust width to be thinner */
  height: 60%; /* Adjust height to be thinner */
}
/* Hide scrollbar for Chrome, Safari and Opera */
html {
  overflow: scroll;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: #FF0000;
}

/* Hide Swiper buttons by default */
.custom-swiper .swiper-button-prev,
.custom-swiper .swiper-button-next {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  background-color: white; /* White background */
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

/* Change arrow colors to grey */
.custom-swiper .swiper-button-prev::after,
.custom-swiper .swiper-button-next::after {
  color: grey;
  font-size: 18px;
}

/* Show navigation buttons only on hover */
.custom-swiper:hover .swiper-button-prev,
.custom-swiper:hover .swiper-button-next {
  opacity: 1;
}
